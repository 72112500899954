// import logo from './logo.svg';
// import './assets/css/componentes/App.scss';
import { motion } from "framer-motion"
// import TextHeader from "../components/TextHeader";
import Three from "../components/Three";
import Title from '../components/Title'
import Idioma from '../components/Idiomas';

function Home() {

    const pageTransition = {
        in: {
          opacity: 1,
        }, 
        out: {
          opacity: 0,
        }
    };
    
    return (
        <div className="home">
            
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 px-0">
                        <div className="container position-relative c_home_idiomas">
                            <Idioma />
                        </div>
                        <motion.div initial="out" animate="in" exit="out" variants={pageTransition} transition={{ duration: 0.5 }}>
                            <Three />
                        </motion.div>
                        <motion.div initial="out" animate="in" exit="out" variants={pageTransition} transition={{ duration: 0.3, delay: 0.1 }}>
                        <Title />
                        </motion.div>
                    </div>        
                </div>
            </div>
        </div>
    );
  }
  
  export default Home;
  