import { motion } from "framer-motion"
import ServiceWeb from '../components/ServiceWeb'
import Star from "../components/Star";

function Apis() {

  const pageTransition = {
    in: {
      opacity: 1
    }, 
    out: {
      opacity: 0
    }
  };



  return (
    <motion.div className='background-blue'  initial="out" animate="in" exit="out" variants={pageTransition} transition={{ duration: 0.5 }}>
      <ServiceWeb />
      <Star />
    </motion.div>
  );
}
  
export default Apis;
  