import { Outlet, Link } from "react-router-dom";
import { motion } from "framer-motion"
import Loading from "./components/Loading";
import { useTranslation } from 'react-i18next';
export default function App() {
  const [t] = useTranslation("global")

  const imageRote = {
    in: {
      y: 0
    }, 
    out: {
      y: 100
    }
  };


  return (
    <div className="app">
      <Loading />
      <Outlet />
      <nav className="navbar">
        <motion.div initial="out" animate="in" exit="out" variants={imageRote} transition={{ duration: 0.5, delay: 3 }}>
        <Link to="/" activeclassname="activeLink">{t("menu.home")}</Link> 
        </motion.div>
        <motion.div initial="out" animate="in" exit="out" variants={imageRote} transition={{ duration: 0.5, delay: 3.2 }}>
        <Link to="/website" activeclassname="activeLink">{t("menu.servicios")}</Link> 
        </motion.div>
        <motion.div initial="out" animate="in" exit="out" variants={imageRote} transition={{ duration: 0.5, delay: 3.4 }}>
        <Link to="/apis" activeclassname="activeLink">{t("menu.apis")}</Link>
        </motion.div>
        <motion.div initial="out" animate="in" exit="out" variants={imageRote} transition={{ duration: 0.5, delay: 3.6 }}>
        <Link to="/bots" activeclassname="activeLink">{t("menu.bot")}</Link> 
        </motion.div>
        <motion.div initial="out" animate="in" exit="out" variants={imageRote} transition={{ duration: 0.5, delay: 3.8 }}>
        <Link to="/cloud" activeclassname="activeLink">{t("menu.cloud")}</Link> 
        </motion.div>
        <motion.div initial="out" animate="in" exit="out" variants={imageRote} transition={{ duration: 0.5, delay: 3.8 }}>
        <Link to="/examples" activeclassname="activeLink">{t("menu.examples")}</Link> 
        </motion.div>
        <motion.div initial="out" animate="in" exit="out" variants={imageRote} transition={{ duration: 0.5, delay: 4 }}>
        <Link to="/contact" activeclassname="activeLink">{t("menu.contacto")}</Link>
        </motion.div>
      </nav>
    </div>
  );
}