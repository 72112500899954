import image from '../assets/image/bot4.png'
import { useTranslation } from 'react-i18next'
import { motion } from "framer-motion"
import Marca from './Marca'
import { useIsSmall } from '../hooks/Utils'
import Idioma from '../components/Idiomas';



const ServiceBots = () => {
    const [t] = useTranslation("global")
    const isSmall = useIsSmall('(min-width: 480px)') 

    const imageRote = {
        in: {
          y: 0
        }, 
        out: {
          y: -100
        }
    };

    const inText = isSmall
    ? {
        in: {
            x: -100
        }, 
        out: {
            x: 0
        }
    } : {
        in: {
            x: -10
        }, 
        out: {
            x: 0
        }
    }

    const marca = {
        in: {
            y: 140,
            opacity: 1,
        },
        out: {
            y: 150,
            opacity: 0
        }
    }
    return (
        <div className='service'>
            <div className='container h-100'>
                <div className='row'>
                    <div className='col-12 left'>
                        <span>{t("servicio.bots.title")}</span>
                        <Idioma />
                    </div>
                    <div className='col-12 right'>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-12 col-md-6 fig-back'>
                                    <figure className="fig" data-aos="fade-right">
                                        <div className="box-img">
                                            <motion.div initial="out" animate="in" exit="out" variants={imageRote} transition={{ duration: 0.5 }}>
                                                <img className="img" width="600" height="300" src={image}  />
                                            </motion.div>
                                        </div> 
                                    </figure>
                                </div>
                                <div className='col-12 col-md-6 box-text-serv'>
                                    <motion.div initial="out" animate="in" exit="out" variants={inText} transition={{ duration: 0.5 }}>
                                    <p className='px-4' style={{ whiteSpace: 'pre-line' }}>{t("servicio.bots.data")}</p>
                                    </motion.div>
                                </div>
                            </div>
                        </div>
                        <motion.div  initial="out" animate="in" exit="out" variants={marca} transition={{ duration: 0.5, delay: 0.5 }}>
                            <Marca />
                        </motion.div >
                    </div>
                </div>
            </div>
        </div>
    )

}

export default ServiceBots;


