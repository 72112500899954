import React, { useState } from "react";
import { useTranslation } from 'react-i18next'
import axios from 'axios';
import { motion } from "framer-motion"
import send from '../assets/image/send2.gif'
const FORM_ENDPOINT = 'enviaEmail/index.php'; // TODO - fill on the later step
//const FORM_ENDPOINT = 'http://localhost/web/enviaEmail/index.php'; // TODO - fill on the later step

const ContactForm = () => {
  const [enviandose, setEnviandose] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submittedError, setSubmittedError] = useState(false);


  const [t] = useTranslation("global")


  const pageTransition = {
    in: {
      opacity: 1
    }, 
    out: {
      opacity: 0
    }
  };



  const handleSubmit = (e) => {
    e.preventDefault();

    setEnviandose(true);

    const inputs = e.target.elements;
    const data = {};

    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].name) {
        data[inputs[i].name] = inputs[i].value;
      }
    }

    //console.log(data)

    const bodyFormData = new FormData();
    bodyFormData.append('nombre', data.nombre);
    bodyFormData.append('email', data.email);
    bodyFormData.append('mensaje', data.mensaje);
    bodyFormData.append('enviar_contacto', 'contacto');

    axios({
      method: "post",
      url: FORM_ENDPOINT,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then(function (response) {
      //handle success
      setEnviandose(false);
      setSubmitted(true)
    })
    .catch(function (response) {
      //handle error
      setEnviandose(false);
      setSubmittedError(response)
      //console.log(response);
    });




  };

  //mensaje de enviandose
  if (enviandose) {
    console.log(enviandose)
  
    return (
      <>
        <div className="text-2xl">
          <motion.div initial="out" animate="in" exit="out" variants={pageTransition} transition={{ duration: 0.5 }}>
          <div className="plane">
            <img className="img-plane" src={send} />
          </div>
          </motion.div>
        </div>
      </>
    );
  }

  //mensaje de éxito
  if (submitted) {
    console.log(submitted)

    var ele = document.getElementById('cont-marca')
    ele.classList.add('transform')

    return (
      <>
        <motion.div initial="out" animate="in" exit="out" variants={pageTransition} transition={{ duration: 0.5 }}>
        <div className="mensaje-success">
          <h1>{t("contacto.respuesta")}</h1>
        </div>
        </motion.div>
      </>
    );
  }

    //mensaje de error
    if (submittedError) {
      //console.log(submittedError)
      return (
        <>
          <div className="text-2xl">{submittedError}</div>
        </>
      );
    }








  return (
    <form
        action={FORM_ENDPOINT}
        onSubmit={handleSubmit}
        method="POST"
        className="form"
        // target="_blank"
    >
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-6 mb-3 pt-0">
                    <input
                        type="text"
                        placeholder={t("contacto.formulario.name")}
                        name="nombre"
                        className="px-3 py-3 placeholder-gray-400 text-gray-600 relative rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
                        required
                    />
                </div>
                <div className="col-12 col-md-6 mb-3 pt-0">
                    <input
                        type="email"
                        placeholder="Email"
                        name="email"
                        className="px-3 py-3 placeholder-gray-400 text-gray-600 relative rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
                        required
                    />
                </div>
                <div className="col-12 mb-3 pt-0">
                    <textarea
                        placeholder={t("contacto.formulario.message")}
                        type="textarea"
                        name="mensaje"
                        className="px-3 py-3 placeholder-gray-400 text-gray-600 relative rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
                        required
                    />
                </div>
                <div className="mb-3 pt-0">
                    <button
                        className="text-white active:bg-blue-600 font-bold uppercase text-sm rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="submit"
                        name="enviar_contacto"
                        value="enviar_contacto"
                    >{t("contacto.formulario.button")}
                    </button>
                </div>
            </div>
        </div>
    </form>
  );
};

export default ContactForm;