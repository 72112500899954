import Logo from '../assets/image/DRI-02.png'



function Title() {
    
    return (
        <div className="content">
  <div className="content__container">
    <p className="content__container__text">
      <div className="content__container__img">
        <img className="img" width="100%" height="auto" src={Logo}  />
      </div>
    </p>
    
    <ul className="content__container__list">
      <li className="content__container__list__item">Website</li>
      <li className="content__container__list__item">API´s</li>
      <li className="content__container__list__item">Bots</li>
      <li className="content__container__list__item">Cloud</li>
    </ul>
  </div>
</div>
    )
}

export default Title