import React from 'react';
import { useTranslation } from 'react-i18next'


export default function Idioma () {
    const [t, i18n] = useTranslation("gloabl")

    return (
        <div className='c_idiomas'>
            <button className={ i18n.language === 'es' ? 'active' : '' } onClick={ () => i18n.changeLanguage('es')}>ES</button>
            <button className={ i18n.language === 'en' ? 'active' : '' } onClick={ () => i18n.changeLanguage('en')}>EN</button>
        </div>
    )
}