import Logo from '../assets/image/DRI-02.png'



function Marca() {
    
    return (
        <div className="cont-marca" id="cont-marca">
            <div className="marca">
                {/* <div className="marca__container">
                    <p className="marca__container__text">
                        MDevelopments
                    </p>
                </div> */}
                <img className="img" width="100%" height="auto" src={Logo}  />
            </div>
        </div>
    )
}

export default Marca