/*Dependencias*/
import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import {I18nextProvider} from 'react-i18next'
import i18next from 'i18next'
import global_es from './idiomas/es.json'
import global_en from './idiomas/en.json'


/*Páginas*/
import Website from './containers/Website';
import Apis from './containers/Apis';
import Cloud from './containers/Cloud';
import Bots from './containers/Bots';
import Contact from './containers/Contact';
import Home from './containers/Home';
import Examples from './containers/Examples';


i18next.init({
  interpolation: {escapeValue: false},
  lng: "es",
  resources: {
    es: {
      global: global_es
    },
    en: {
      global: global_en
    }
  }
})

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <I18nextProvider i18n = {i18next}>
    <AnimatePresence exitBeforeEnter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="/" element={<Home />}/>
          <Route path="website" element={<Website />} />
          <Route path="apis" element={<Apis />} />
          <Route path="cloud" element={<Cloud />} />
          <Route path="bots" element={<Bots />} />
          <Route path="examples" element={<Examples />} />
          <Route path="contact" element={<Contact />} />
        </Route>
      </Routes>
    </AnimatePresence>
    </I18nextProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
