



function star() {




    return (
        <div class="ibox">
            <ul class="circles">
                <li>D</li>
                <li>R</li>
                <li>I</li>
                <li>D</li>
                <li>E</li>
                <li>V</li>
                <li>E</li>
                <li>L</li>
                <li>O</li>
                <li>P</li>
            </ul>
        </div>
    )
}

export default star;
