import { motion } from "framer-motion"
import Examples from '../components/Examples'
import Star from "../components/Star";


function Apis() {

  const pageTransition = {
    in: {
      opacity: 1
    }, 
    out: {
      opacity: 0
    }
  };




  return (
    <motion.div initial="out" animate="in" exit="out" variants={pageTransition} transition={{ duration: 0.5 }}>
      <Examples />
      <Star />
    </motion.div>
  );
}

export default Apis;
  