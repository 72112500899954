import React, { useRef } from 'react'
import { Canvas, useFrame, useLoader  } from '@react-three/fiber'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import mapa from '../assets/texturas/test2.jpg'
import mapa2 from '../assets/texturas/Metal030_1K_Metalness.jpg'
import { OrbitControls, Stars, Cloud } from '@react-three/drei'


function Box(props) {
  const mesh = useRef()
  const colorMap = useLoader(TextureLoader, mapa)
  const colorMap2 = useLoader(TextureLoader, mapa2)
  useFrame((state, delta) => { mesh.current.rotation.y += 0.001;})
  return (
    <mesh
      {...props}
      ref={mesh}
      scale={1.7}
      rotation-x={Math.PI * 0.25} rotation-y={Math.PI * 0.25}
      >
      <sphereBufferGeometry />
      <meshStandardMaterial map={colorMap} bumpMap={colorMap2} />
    </mesh>
  )
}


function Three() {
  
  return (
    <Canvas className='canvas'>
    <OrbitControls zoom0={true}/>
    <ambientLight intensity={0.09} />
    <pointLight position={[20, 15, 5]} />
    <Box position={[0, 0, 0]} />
    <Stars radius={100} depth={50} count={5000} factor={1} saturation={0} fade speed={1} />
    <Cloud
    opacity={0.4}
    speed={0.1} // Rotation speed
    width={20} // Width of the full cloud
    depth={1.5} // Z-dir depth
    segments={15} // Number of particles
    />
    </Canvas>
  );
}
  
export default Three;
  