import { motion } from "framer-motion"
import Formulario from '../components/Formulario'
import Marca from '../components/Marca'
import { useTranslation } from 'react-i18next'
// import { useIsSmall } from '../hooks/Utils'
import MarcaMovil from "../components/MarcaMovil";
import Idioma from '../components/Idiomas';


function Contact() {
  const [t] = useTranslation("global")
  // const isSmall = useIsSmall('(min-width: 480px)') 

  const pageTransition = {
    in: {
      opacity: 1
    }, 
    out: {
      opacity: 0
    }
  };
    const marca = {
      in: {
        x: -108,
        y: 70,
        opacity: 1
    },
    out: {
        y: 100,
        x: -108,
        opacity: 0
    }
  }


    return (
      <div className='service contacto'>
        <div className='container h-100'>
            <div className='row'>
              <div className='col-12 left'>
                  <span>{t("contacto.title")}</span>
                  <Idioma />
              </div>
              <div className='col-12 mt-5'>
              <motion.div initial="out" animate="in" exit="out" variants={pageTransition} transition={{ duration: 0.5 }}>
                <Formulario />
              </motion.div>
              <motion.div  initial="out" animate="in" exit="out" variants={marca} transition={{ duration: 0.5, delay: 0.5 }}>
                  <Marca />
              </motion.div >
              </div>
            </div>
        </div>
    </div>
    );
  }
  
  export default Contact;
  